@font-face {
  font-family: 'SofiaPro';
  font-weight: 100;
  src: local('SofiaPro'), url(./fonts/SofiaPro-UltraLight.woff2) format('woff2'),
    url(./fonts/SofiaPro-UltraLight.woff) format('woff'), url(./fonts/SofiaPro-UltraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'SofiaPro';
  font-weight: 100;
  src: local('SofiaPro'), url(./fonts/SofiaPro-UltraLight.woff2) format('woff2'),
    url(./fonts/SofiaPro-UltraLight.woff) format('woff'), url(./fonts/SofiaPro-UltraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'SofiaPro';
  font-weight: 200;
  src: local('SofiaPro'), url(./fonts/SofiaPro-ExtraLight.woff2) format('woff2'),
    url(./fonts/SofiaPro-ExtraLight.woff) format('woff'), url(./fonts/SofiaPro-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'SofiaPro';
  font-weight: 300;
  src: local('SofiaPro'), url(./fonts/SofiaPro-Light.woff2) format('woff2'),
    url(./fonts/SofiaPro-Light.woff) format('woff'), url(./fonts/SofiaPro-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'SofiaPro';
  font-weight: 400;
  src: local('SofiaPro'), url(./fonts/SofiaProRegular.woff2) format('woff2'),
    url(./fonts/SofiaProRegular.woff) format('woff'), url(./fonts/SofiaProRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'SofiaPro';
  font-weight: 500;
  src: local('SofiaPro'), url(./fonts/SofiaPro-Medium.woff2) format('woff2'),
    url(./fonts/SofiaPro-Medium.woff) format('woff'), url(./fonts/SofiaPro-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'SofiaPro';
  font-weight: 600;
  src: local('SofiaProS'), url(./fonts/SofiaPro-SemiBold.woff2) format('woff2'),
    url(./fonts/SofiaPro-SemiBold.woff) format('woff'), url(./fonts/SofiaPro-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'SofiaPro';
  font-weight: 700;
  src: local('SofiaPro'), url(./fonts/SofiaPro-Bold.woff2) format('woff2'),
    url(./fonts/SofiaPro-Bold.woff) format('woff'), url(./fonts/SofiaPro-Bold.ttf) format('truetype');
}
