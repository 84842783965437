@import './styles/fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: SofiaPro;
  font-size: 14px;
  background: white;
}

@layer base {
  h1 {
    @apply text-3xl font-bold;
  }
}

html,
body,
#root {
  height: 100%;
}
